.dashboard-header-renew {
    z-index: 0;
}

.made-image {
    background: #eef4f6 0% 0% no-repeat padding-box;
    border-radius: 8px;
    min-width: 93px;
    height: 71px;
}

.card .svg-icon svg {
    object-fit: contain;
}

.card span.arrow-icon {
    height: auto;
    width: 15px;
}

.card span.arrow-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card span.number-title {
    gap: 5px;
}

.number-title span:first-child {
    font-weight: 500;
    line-height: 1.2;
}

.card-box {
    padding: 22px 32px 33px 31px;
}

.europe,
.americas,
.asia,
.africaAndOthers {
    height: 70px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
}

.map-data .arrow-icon {
    display: none;
}

.europe {
    background-image: url('../../../../../../public/media/svg/programme_explorer_supporter/europe.svg');
}

.americas {
    background-image: url('../../../../../../public/media/svg/programme_explorer_supporter/usa.svg');
}

.africaAndOthers {
    background-image: url('../../../../../../public/media/svg/programme_explorer_supporter/africa-and-outher.svg');
}

.asia {
    background-image: url('../../../../../../public/media/svg/programme_explorer_supporter/asia.svg');
}

.map-data span.number-title:first-child {
    font-size: 24px;
    margin: 15px 0;
    font-weight: 600;
}

.card-data-list {
    max-width: 370px;
}

.card.card-box {
    margin-bottom: 15px;
}

@media (max-width: 991px) {

    .europe,
    .americas,
    .asia,
    .africaAndOthers {
        width: 60px;
    }
}

@media (max-width: 767px) {
    .image-data-box {
        justify-content: center;
    }

    .card-data-list>div {
        flex: 0 0 45%;
    }

    .card-data-list {
        flex-wrap: wrap;
    }

    .card.card-box {
        text-align: center;
    }
}

.cartcmetitle .cardtitle {
    font-size: 25px;
    font-weight: 600;
    color: #333333;
    padding: 12px 30px;
    display: inline-block;
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 17px;
    max-width: 680px;
    width: 100%;
}

.cardcme {
    background: #ffffff;
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 17px;
    padding: 28px;
    max-width: 155px;
    width: 100%;
    text-align: center;

    .cardpercentwrap {
        min-height: 78px;
        color: #333333;

        .card-top-title {
            font-weight: 600;
        }
    }
}

.cardcmewrap {
    gap: 50px;
    display: flex;
    flex-wrap: wrap;
}

.cardcme .cardimage {
    background: #eef4f6;
    border-radius: 8px;
    margin-bottom: 6px;
    padding: 13px;
}

.cardcme .cardpercent {
    font-size: 23px;
}

.cardcme .carddesc {
    font-size: 15px;
}

.cardlevel .cardhead {
    font-size: 10px;
}

.cardlevel .cardnumber {
    font-size: 15px;
}