.rounded-60 {
  border-radius: 60px !important;
}
.h-90 {
  height: 90% !important;
}
.justify {
  justify-content: unset !important;
}
.svg-icon-orange {
  color: #cc643d !important;
}
