//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';

// Programme Explorer Supporter
@import './core/programme_explorer_supporter/cards.scss';


.sticky-header {
    .shadow-sm {
        box-shadow: none !important;
    }

    .category-nav {
        background-color: white;
        border-radius: 0 0 10px 10px;
        box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 1%) !important;
    }
}

.dashboard-header {
    z-index: 104;
    display: block !important;
    position: relative !important;

    div {
        &:first-child {
            position: relative;
        }
    }

}

.header-fixed {
    .header {
        z-index: 3200 !important;
    }
}

.header-to-back {
    z-index: 103 !important;
}

custom-metric-input {
    outline: 0;
    border-width: 0 0 2px;
}

.custom-loader {
    animation: none !important;
    border-width: 0 !important;
}

.custom-loader-icon {
    margin: 1em auto 0em !important;
    border: none !important;
}

.overflow-visible {
    overflow: visible !important;
}

.btn-apply {
    background-color: #cc643d !important;
    color: white;
    &:hover {
        color: white;
    }
}

.text-apply {
    color: #cc643d !important;
}

.inline-edit-label {
    text-decoration: dotted underline;
}

.swal2-container { 
    z-index: 3500 !important; 
}

.toast .toast-close-button {
    font-size: 20px;
}

.bg-marketing-insight {
    background-color: #DAE8FC !important;
    color: black !important;
    border-top-left-radius: 5px;
}
.bg-marketing-insight-not-rounded {
    background-color: #DAE8FC !important;
    color: black !important;
}
.bg-marketing-fields {
    background-color: #D5E7D4 !important;
    color: black !important;
}
.bg-marketing-fields-final {
    background-color: #D5E7D4 !important;
    color: black !important;
}
.bg-marketing-fields-init {
    background-color: #D5E7D4 !important;
    color: black !important;
}

.bg-marketing-extended-website-header{
    background-color: #AFE3E6 !important;
    color: black !important;
}
.pre-line {
    white-space: pre-line;
}

.svg-table-icon {
  width: 45px !important;
  height: 45px !important;
}

.svg-table-icon-2 {
    width: 25px !important;
    height: 25px !important;
}

.fs-12p {
    font-size: 12px !important;
}

.min-w-200px {
    min-width: 200px !important;
}

.min-w-100px {
    min-width: 100px !important;
}

.min-h-350px {
    min-height: 350px !important;
}

.small-spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
}

.small-spinner-wrapper svg {
    font-family: "Russo One", sans-serif;
    width: 100%;
    height: 100%;
}
.small-spinner-wrapper svg text {
    animation: stroke 1.5s infinite alternate;
    stroke-width: 2;
    stroke: #477995;
    font-size: 20px;
}

.small-spinner-wrapper svg text tspan.orange-spinner {
    animation: strokeOrange 1.5s infinite alternate;
    stroke-width: 2;
    stroke: #CC643C;
    font-size: 20px;
}

@keyframes stroke {
    0% {
      fill: rgba(71, 121, 149, 0);
      stroke: rgba(71, 121, 149, 1);
      stroke-dashoffset: 25%;
      stroke-dasharray: 0 50%;
      stroke-width: 1;
    }
    70% {
      fill: rgba(71, 121, 149, 0);
      stroke: rgba(71, 121, 149, 1);
    }
    80% {
      fill: rgba(71, 121, 149, 0);
      stroke: rgba(71, 121, 149, 1);
      stroke-width: 1.5;
    }
    100% {
      fill: rgba(71, 121, 149, 1);
      stroke: rgba(71, 121, 149, 0);
      stroke-dashoffset: -25%;
      stroke-dasharray: 50% 0;
      stroke-width: 0;
    }
}

@keyframes strokeOrange {
        0% {
          fill: rgba(71, 121, 149, 0);
          stroke: rgba(204,100,60,1);
          stroke-dashoffset: 25%;
          stroke-dasharray: 0 50%;
          stroke-width: 1;
        }
        70% {
          fill: rgba(71, 121, 149, 0);
          stroke: rgba(204,100,60,1);
        }
        80% {
          fill: rgba(71, 121, 149, 0);
          stroke: rgba(204,100,60,1);
          stroke-width: 1.5;
        }
        100% {
          fill: rgba(204,100,60,1);
          stroke: rgba(71, 121, 149, 0);
          stroke-dashoffset: -25%;
          stroke-dasharray: 50% 0;
          stroke-width: 0;
        }
}

.custom-overlay {
    position: absolute;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: start;
}

.position-relative {
    position: relative !important;
}

.z-index-2000 {
    z-index: 2000 !important;
}

.z-index-3000 {
    z-index: 3000 !important;
}

.z-index-3500 {
    z-index: 3500 !important;
}

.mt-custom {
    margin-top: 200px !important;
}

.medium-spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 60px;
}

.medium-spinner-wrapper svg {
    font-family: "Russo One", sans-serif;
    width: 100%;
    height: 100%;
}
.medium-spinner-wrapper svg text {
    animation: stroke 1.5s infinite alternate;
    stroke-width: 4;
    stroke: #477995;
    font-size: 40px;
}

.medium-spinner-wrapper svg text tspan.orange-spinner {
    animation: strokeOrange 1.5s infinite alternate;
    stroke-width: 4;
    stroke: #CC643C;
    font-size: 40px;
}

.modal {
    z-index: 3500 !important;
}

.li-custom-metrics {
    list-style: none;
    display: flex;
    justify-content: center;
    margin-bottom: 0.75rem;
}

.li-custom-metrics-operations {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 50px;
}

.span-operation {
    color: white;
    padding: 5px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 25px;
    background-color: #477995;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
}

.span-custom {
    color: white;
    padding: 5px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 10px;
    background-color: #CC643C;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
}

.span-manual {
    color: white;
    padding: 5px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 10px;
    background-color: #9596A0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
}

.operations-min-height {
    min-height: 220px;
}

.custom-delete-border {
    border: 1px solid #f1416c;
    border-style: dashed;
    border-radius: 10px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 10px;
}

.custom-edit-border {
    border: 1px solid #ffc700;
    border-style: dashed;
    border-radius: 10px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 10px;
}

.customMetrics-modal-z-index {
    z-index: 99999 !important;
}