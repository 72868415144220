.swipe-list-outerBox {
    position: relative;
    padding-bottom: 8px 8px;
    max-width: 100%;
    width: 100%;
    min-width: 310;
}

.swipe-list-innerBox {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.swipe-list-innerBox::-webkit-scrollbar {
    /* WebKit */ width: 0;
    height: 0;
}


.swipe-list-content {
    flex: 0 0 300px;
    margin: 5;
}

.swipe-list-btnBox {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: transparent;
    transition: all 1s;
    cursor: pointer;
}

.swipe-list-leftBtnBox {
    left: 0;
}

.swipe-list-rightBtnBox {
    right: 0;
}

.swipe-list-btn {
    position: relative;
    top: 50%;
    bottom: 50%;
    transform: translateY(-50%);
}

.swipe-list-bg-active {
    background-color: #477995;
}