.programme-explorer-card {
    margin-bottom: 20px;
}

.animate-size {
    transition: .2s width ease-in-out;
}

.counter-font {
    font-size: 3em;
}

.programme-explorer-card.not-opened {
    max-height: 310px !important;
}

.programme-explorer-card-orange {
    background-color: #c6573b;
    padding: 28px;
}


.cardcmewrap {
    gap: 20px !important
}
.cardcme {
    max-width: 200px !important;
    max-height: auto;
    align-items: center;
    
}

.cardcme .cardimage {
    width: 40%;
    min-height: 50px;
}

.cardcme .cardimage img {
    transform: scale(1.5);
}
.cardcme .cardpercentwrap-orange .card-top-title {
    color: white !important;
    font-size: 20px !important;
    margin: 5px;
}

.cardcme .cardpercentwrap-orange {
    color: white !important;
}

.cardcme .cardlevel-orange {
    color: white;
    margin: 20px;
}

.cardimage .card-svg-fill-orange {
    filter: invert(44%) sepia(71%) saturate(647%) hue-rotate(327deg) brightness(83%) contrast(85%);
}

.cardcme .cardpercentwrap-white {
    color: #333333 !important;
}

.cardcme .cardlevel-white {
    color: #333333;
    margin: 20px;
}

.cardcme .cardpercentwrap-white .card-top-title {
    color: #333333 !important;
    font-size: 20px !important;
    margin: 5px;
}

.title-supporter {
    color: #477995;
    font-weight: bold;
    text-decoration: underline;
    margin-top: 20px;
}

.sign-title {
    color: #c6573b;
    font-size: 14px;
}

.subtitle-supporter {
    margin-bottom: 15px;
}

.impact-description {
    height: 55px;
}

.height-impact {
    height: 255px !important;
}

.no-wrap-text {
    overflow: scroll;
    overflow-x: hidden;
}

.card-top-title-multiple {
    font-size: 15px !important;
}

.custom-disabled {
    pointer-events: auto;
  }
  
  .buttons {
    display: flex;
  }

  .buttons div {
    margin-left: 5px;
    margin-right: 5px;
  }