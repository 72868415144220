.programme-explorer-card {
    margin-bottom: 20px;
}

.animate-size {
    transition: .2s width ease-in-out;
}

.counter-font {
    font-size: 3em;
}

.counter-font-large {
    font-size: 2em;
}

.programme-explorer-card.not-opened {
    max-height: 310px !important;
}

.programme-explorer-card.not-opened .card {
    height: 100%;
}

.programme-explorer-card.not-opened .main-view-card {
    display: block;
    min-height: 215px;
}

.programme-explorer-card.opened .main-view-card {
    display: none !important;
}

.programme-explorer-card .chart-direction-counter .mh-50px {
    max-height: 24px !important;
    width: 24px !important;
}
.programme-explorer-card .main-view-card-header {
    flex: 0 0 auto;
    height: 105.3px;
}

.custom-disabled {
    border-radius: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-decoration: none;
    font-weight: 500;
    color: #7e8299;
    opacity: 0.6;
    cursor: pointer;
    margin-right: 10px;
  }
